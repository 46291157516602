import axios from 'axios'
import React, { createContext, useContext, useState } from 'react'
import { AdminProfileEnd, GetStudentProfileEnd } from '@/utils/EndPoints'
//
// user,
// setUser: login,
// getUserInfo: getUserInfo,
// admin,
// setAdmin: adminLogin,
// initializing,
// previousPath,
// setPreviousPath: initializePreviousPath,

const AuthContext = createContext({
  user: {},
  setUser: () => {},
  getUserInfo: async (token) => {},
  admin: { role: [] },
  setAdmin: () => {},
  previousPath: '',
  isAdminLoading: true,
  isUserLoading: true,
})

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const [admin, setAdmin] = useState({
    role: [],
  })
  const [previousPath, setPreviousPath] = useState('')

  const [isAdminLoading, setIsAdminLoading] = useState(true)
  const [isUserLoading, setIsUserLoading] = useState(true)
  // const [browserToken, setBrowserToken] = useState('')

  const login = React.useCallback((u) => {
    setUser(u)
  }, [])

  const adminLogin = React.useCallback((u) => {
    setAdmin(u)
  }, [])

  const initializePreviousPath = React.useCallback((u) => {
    setPreviousPath(u)
  }, [])

  React.useEffect(() => {
    let token = localStorage.getItem('precise-mentorship')
    if (token) {
      getUserInfo(token)
    } else {
      setUser({})
      setIsUserLoading(false)
    }
  }, [])

  React.useEffect(() => {
    const adminToken = localStorage.getItem('precise-mentorship-admin')
    if (adminToken) {
      getAdminInfo(adminToken)
    } else {
      setAdmin({})
      setIsAdminLoading(false)
    }
  }, [])

  const getAdminInfo = async (token) => {
    try {
      const response = await axios.get(AdminProfileEnd, {
        headers: {
          'precise-mentorship': token,
        },
      })
      if (response.status === 200) {
        setAdmin(response.data.data)
      } else {
        throw new Error(response?.data?.msg)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsAdminLoading(false)
    }
  }

  const getUserInfo = async (token) => {
    try {
      const response = await axios.get(GetStudentProfileEnd, {
        headers: {
          'precise-mentorship': token,
        },
      })

      if (response.status === 200) {
        setUser(response.data.data)
      } else throw new Error(response?.data?.msg)
    } catch (error) {
      console.log(error)
    } finally {
      setIsUserLoading(false)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser: login,
        getUserInfo: getUserInfo,
        admin,
        setAdmin: adminLogin,
        isAdminLoading,
        isUserLoading,
        previousPath,
        setPreviousPath: initializePreviousPath,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
export default AuthProvider
export const useAuth = () => useContext(AuthContext)
