import Head from 'next/head'
import React from 'react'
import GoTop from '@/components/_App/GoTop'

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Head>
        <title>Precise Mentorship</title>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, shrink-to-fit=no'
        />
        <meta
          name='description'
          content='Precise Mentorship - Lets create a Story
'
          key='description'
        />
        <meta
          property='og:url'
          content={process.env.NEXT_PUBLIC_Frontend_URI}
        ></meta>
        <meta
          name='og:title'
          property='og:title'
          content='Precise Mentorship'
          key='title'
        ></meta>
        <meta
          property='og:description'
          content='Precise Mentorship-Lets create a Story'
        ></meta>
        <meta
          property='og:image'
          content='https://res.cloudinary.com/precise-mentorship/raw/upload/v1643791160/ydup7f2wr2laqfrkivem.png'
        />
        <meta
          name='twitter:card'
          content='Precise Mentorship-Let&#39;s Create a Story Together'
        ></meta>
        {/* <link rel='canonical' href='https://edemy-react.envytheme.com/'></link> */}
      </Head>

      {children}

      <GoTop scrollStepInPx='100' delayInMs='10.50' />
    </React.Fragment>
  )
}

export default Layout
